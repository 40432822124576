import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import { MdKeyboardArrowDown } from 'react-icons/md';
import api from '~/services/api';
import { AlertBox, DataList } from './styles';
import Select, { AsyncSelect } from '@atlaskit/select';

interface IFormData {
  text: string | any;
  option: { label: string; value: number | string } | any;
}

// type TipoPessoa = 'Cliente' | 'Fornecedor' | 'Loja';

interface Options {
  label: string | undefined;
  value: number | undefined | string;
}

interface Fornecedor {
  cod_pessoa: number;
  cod_fornecedor: number;
  des_fornecedor: string;
  num_cpf_cnpj: string;
  flg_inativo?: boolean;
}

interface Select {
  label: string;
  value: number;
  flg_inativo?: boolean;
}

interface BuscaFornecedorProps {
  onChange(e: any): void;
  children?: ReactNode;
  refs?: any;
  placeholder?: string;
  label?: string;
  value: string | undefined;
  init: boolean;
  invalid?: boolean;
  required?: boolean;
}

export const BuscaFornecedor: React.FC<BuscaFornecedorProps> = forwardRef(
  (props) => {
    const {
      children,
      init,
      label,
      required = false,
      invalid = false,
      placeholder = 'Digite para buscar o Fornecedor',
      onChange,
      value = null,
    } = props;
    const [isInvalid, setIsInvalid] = useState(false);
    const [inicializado, setInicializado] = useState(false);
    const [showOption, setShowOptions] = useState(false);
    const [filtroInicial, setFiltroInicial] = useState('');
    const [resultadoArmazenado, setResultadoArmazenado] = useState([]);
    const inputRef = useRef<any>(null);
    const [store, setStore] = useState<Select[]>([]);
    const [storeFiltered, setStoreFiltered] = useState<Select[]>([]);
    useEffect(() => {
      setInicializado(init);
    }, [init]);

    useEffect(() => {
      setIsInvalid(invalid);
    }, [invalid]);

    const addAlert = useCallback((): string => {
      if (inicializado && isInvalid) {
        return 'alert-class';
      }
      return '';
    }, [inicializado, isInvalid]);

    useImperativeHandle(props.refs, () => ({
      handleClearFornecedor: () => handleClear(),
    }));

    const handleClear = () => {
      setFiltro(null);
    };

    const [filtro, setFiltro] = useState<Select | null>(null);

    useEffect(() => {
      if (value) {
        const hasExists = store.find(
          (reg) => Number(reg.value) === Number(value),
        );

        if (!hasExists) {
          return;
        }
        setFiltro({
          label: hasExists?.label,
          value: hasExists?.value,
        });
      } else {
        setFiltro(null);
      }
    }, [value, store]);

    const handleChange = useCallback((e: any) => {
      onChange(e);
      setFiltro(e);
    }, []);

    const getFornecedores = async () => {
      const res = await api.get('/fornecedor/buscaFornecedor');
      const { data } = res.data;

      const options = data.map((fornecedor: Fornecedor) => {
        return {
          label: `${fornecedor.des_fornecedor}`,
          value: fornecedor.cod_fornecedor,
          des_fornecedor: fornecedor.des_fornecedor,
          cod_fornecedor: fornecedor.cod_fornecedor,
          cod_pessoa: fornecedor.cod_pessoa,
          flg_inativo: fornecedor.flg_inativo,
        };
      });
      setStore(options);
      setStoreFiltered([]);
    };

    useEffect(() => {
      getFornecedores();
      // const getFornecedores = () => {};
      // setStore(options);
      // return options;
    }, []);

    const handleFilter = (e: string) => {
      if (e === '') {
        return setStoreFiltered([]);
      }

      const filtered = store.filter(
        (data) => data.label.indexOf(e.toUpperCase()) > -1,
      );

      const onlyActives = filtered.filter((f) => f.flg_inativo !== true);
      setStoreFiltered(onlyActives);
    };

    return (
      <>
        <AlertBox>
          <div className={addAlert()}>
            <Field name="filtro" defaultValue="" label={label}>
              {() => (
                <>
                  <Select
                    options={storeFiltered}
                    ref={inputRef}
                    className="async-select-with-callback"
                    classNamePrefix="react-select"
                    placeholder={placeholder}
                    onChange={handleChange}
                    onInputChange={handleFilter}
                    onMenuOpen={() => {
                      const storeSelected = store.find(
                        (s) => s.value === Number(value),
                      );

                      if (storeSelected) {
                        const s = {
                          label: storeSelected.label,
                          value: storeSelected.value,
                        };

                        setFiltro(s);
                      } else {
                        setFiltro(null);
                      }
                    }}
                    value={filtro}
                    noOptionsMessage={() => 'Nenhum registro encontrado'}
                  />
                </>
              )}
            </Field>
          </div>
        </AlertBox>
      </>
    );
  },
);
