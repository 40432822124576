import React from 'react';
import RegraDeLoja from '~/pages/RegraDeLoja';
import { RegraLojaContextProvider } from './RegraLojaContext';

const RegraContent = () => {
  return (
    <RegraLojaContextProvider>
      <RegraDeLoja />
    </RegraLojaContextProvider>
  );
};
export default RegraContent;
